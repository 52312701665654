
.homeSection1 .carousel-item {
  height: 450px; 
  position: relative;
}
.homeSection1{
  position: absolute;
    width: 100%;
  }
.homeimage{
  width: 100%;
  position: relative;
  height: auto; 
  object-fit: cover;
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.homeimage1,
.homeimage2,
.homeimage3,
.homeimage4 {
  height: 100%; 
  width: 100%;
}


/* @media screen and (max-width: 390px){
  .heading2{
    top: 100% !important;
    margin-top: 70px;
  }
  #heading2{
    top: 137vh !important;
  }
  .scrollContent{
    font-size: 10.6px !important;
    text-align: justify !important;
  }
}
@media screen and (max-width: 412px){
  .heading2{
    margin-top: -20px;
  }
}
@media screen and (max-width: 768px) {
  .homeSection1 .carousel-item {
    height: 300px; 
  }
  .homeSection1 .carousel-caption{
    top: 40%;
  }
  .homeSection1 .carousel-caption h1{
    font-size: 28px;
  }
}
@media screen and (max-width: 576px) {
  .homeSection1 .carousel-item {
    height: 200px; 
  }
  .homeSection1 .carousel-caption{
    top: 40%;
  }
  .homeSection1 .carousel-caption h1{
    font-size: 28px;
  }
} */
.carousel-caption {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-0%, -50%);
}
.carousel-caption h1 {
  font-size: 65px; /* Adjust the font size as needed */
}
.imaged{
  width: -5%;
  height: 20vh;
 overflow: hidden;
}

.image5{
  position: absolute; 
  top: 120.5%;
  width: 50%;
  height: 60vh;
  /* overflow: hidden; */

}

@media screen and (max-width: 2647px){
  .image5{
   top:149%;
   height: 50vh;
   width: 50%;
 }

}
@media screen and (min-width: 2648px){
  .image5{
   top: 162%;
   height: 32vh;
   width: 50%;
 } 

}
.textContainer{
  position: relative;
  display: flex;

}
.image5Text {
  position: relative;
  text-align: justify;
  width: 50%;
  padding: 20px;
  margin-left: 50%;
  top: 500px;
}

/* @media screen and (max-width: 430px){
  .heading2{
    top: 79% !important;
  }
}
@media screen and (max-width: 360px){
  .heading2{
    position: relative;
    top: 84% !important;
  }
}
@media screen and (max-width: 414px){
  .heading2{
    top: 94% !important;
    margin-top: -100px;
  }
}
@media screen and (max-width: 375px){
.heading2{
  top: 135% !important;
}
.scrollContent{
  font-size: 10.4px !important;
  text-align: justify !important;
}
} */
/* @media screen and (max-width: 576px) {
  .image5Text {
    font-size: 6px;
    top: 200px;
    padding: 10px;
  }
  .image5Text h2{
    font-size: 10px;
  }
  .image5{
    top: 220px;
  }
} */
.container2{
  top: 465px;
  position: relative;
}
.hbl{
  margin-left: 25px;
  color: #16214f;
  border-bottom: #16214f 5px solid;
  width: 40%;
  padding-bottom: 13px;
}
/* @media screen and (max-width: 576px){
  .container2{
    top: 175px;
  }
  .hbl{
    font-size: 10px;
    border-bottom: #16214f 2px solid;
    padding-bottom: 8px;
  }
} */
.container3{
  padding: 25px;
  position: relative;
  width: 50%;
  margin-right: 50%;
}
.image6{
  position: absolute; 
  top: 0%;
  width: 105%;
  height: 105%;
  margin-left: 90%;
}
/* @media screen and (max-width: 768px){
  .oe{
    font-size: 10px;
  }
  .container3{
    font-size: 6px;
  }
  .container3 p{
    margin-right: 0px;
  }
  .image6{
    width:210px;
    height: 190px;
  }
} */
.container4{
  position: relative;
  display: flex;
  flex-direction: column;
  top: 450px;
  margin-right: 50%;
  padding: 25px;
}
.list{
  margin-right: 15%;
}
.blue{
  /* color: #2A41BA; */
  font-weight: bolder;
}

.heading2{
  top: 90px;
  position: relative;
  color: #16214f;
  margin-left: 50%;
  border-bottom: #16214f 5px solid;
  width: 50%;
  padding-bottom: 13px;
  text-align: center;
}
.head3{
  top:90px;
  position: relative;
  color: #16214f;
  margin-left: 50%;
}
.para{
  top: 90px;
  margin-left: 50%;
  position: relative;
}
/* @media screen and (max-width: 768px){
  .container4{
    position: relative;
  display: flex;
  margin-right: 50%;
  padding: 25px;
    top: 130px;
    font-size: 6px;
  }
 
  .op{
    font-size: 10px;
  }
  .list{
    padding-left: 2px;
  }
  .heading2{
    position: absolute;
    margin-left: 50%;
    width: 50%;
    top: 82% ;
    border-bottom: #16214f 2px solid;
    font-size: 10px;
  }
  .head3{
    position: relative;
    margin-left: 50%;
    top: 0%;
    font-size: 10px;
  }
  .para{
    font-size: 6px;
    top: 0%;
    position: relative;
  }
  
}
 */

.container6{
  top: 100px;
  height: 530px;
  position: relative;
  background-color: #16214f;
  overflow: hidden;
}
.scrollBox {
  position: absolute;
  bottom: 0px;
  left: 15%;
  top: 90px;
  margin-bottom: 29px;
  padding: 2%;
  transform: translateX(-50%);
  background-image: url('../image/home7.png');
  width: 1000px;
  max-width: 900px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
  background-color: rgba(255, 255, 255, 0.2);
  border: #d5984e 1px solid;
  overflow-y: auto;
  animation: slideUp 5s ease forwards;
 
}
.scrollContent {
  color: #fff;
  font-size: 16px;
  width: 800px;
  text-align: justify;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.yic{
  position: relative;
  top: 20px;
  color: #d5984e;
  text-align: center;
  font-weight: 700;

}
/* @media screen and (max-width: 768px) {
  .container6{
    top: 10px;
    width: auto;
    position: relative;
  }
  .yic{
    font-size: 20px;
  }
  .scrollBox{
    width: auto;
    height: 80%;
    left: 0%;
    top: 12%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 0px;
    transform: translateX(-50%);
  }
  .scrollContent{
    width: 100%;
    font-size: 11px;
  }
  .scrolll{
    font-size: 16px;
  }
  .footer{
    position: relative;
    top: 0% !important;
  }
} */
.footer{
  top: 100px;
  position: relative;
}