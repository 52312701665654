.container7{
    /* margin-top: 800%; */
    /* position: absolute; */
   /* height: 400px; */
   background-color: #E2E4ED;
   /* display: flex;
    flex-direction: column;
    align-items: center; */
    margin-bottom: 0px;
  }
  .connect{
    font-size: 100px;
    font-weight: 800;
    color: #fff;
    text-shadow: 7px 7px 7px #6B6464;
    text-align: center;
    letter-spacing: 10px;
  }
  #number{
    margin-right: 80px;
  }
  
  .iconWrapper {
    display: flex;
    flex-direction: row; 
    align-items: center;
    margin-top: 20px;
    margin-left: 200px;
  }
  
  .iconCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0px 10px 0px 50px;
    margin-bottom: 20px;
    /* margin: 10px 20px; */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .icon {
    color: #16214f;
    font-size: 40px;
  }
  
  .contactInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px; /* Add margin between icons and contact information */
  }
  
  .contactLink {
    color: #665E5E;
    font-size: 25px;
    text-decoration: none; /* Remove underline */
    margin-bottom: 5px; /* Adjust margin between contact links */
  }
  #number{
    margin-bottom: 10px;
  }
  #mail{
    margin-bottom: 25px !important;
  }
  .contactLink:hover {
    transform: scale(1.1); /* Enlarge the contact link on hover */
  }
  /* @media screen and (max-width: 768px) {
    .container7 {
      top: 157.5%;
      width: 100%; 
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #E2E4ED;
      overflow: hidden;
    }
    .iconWrapper {
      display: flex;
      flex-direction: column; 
      align-items: center; 
      margin-top: 20px;
      margin-right: 230px;
    }
    .iconCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #fff;
      margin-bottom: 10px; 
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    }
    #number{
      margin-right: 0px;
    }
    .icon {
      color: #16214f;
      font-size: 40px;
    }
    .contactInfo {
      display: flex;
      margin-left: 25px;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      text-align: center;
    }
    .contactLink {
      color: #665E5E;
      font-size: 20px;
      text-decoration: none;
      margin-bottom: 10px; 
      text-align: center;
      margin-left: 10px;
    }
   
    .connect {
      font-size: 60px;
      letter-spacing: 5px; 
      margin-bottom: 20px; 
    }
  } */
  /* @media screen and (max-width: 768px){
    .followtext {
      text-align: center;
      margin-left: 20px;
    }
    .quicklinks{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: -15%;
    }
    .last{
      text-align: center;
      margin: 10px !important;
    }
    .inslink{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: -17% !important;
    }
  } */
  .quicklinks {
    background-color: #E2E4ED; 
    padding: 10px;
  }
  .quicklinks span{
    position: relative;
    color: #16214f;
    font-size: 25px;
    margin:40px;
    margin-left: 110px;
    transition: transform 0.3s ease;
  }
  .quicklinks span:hover {
    border-bottom: 3px solid #16214f; 
    font-size: 28px;
  }
 .inslink{
  margin-left: 44%;
 }
  .follow a:hover{
   font-size: 20px;
  }
  .decide {
    border-radius: 0; 
    transition: border-radius 0.3s; 
  }
  .decide:hover {
    border-radius: 13px;
  }
  .last{
    /* margin-left: 32%; */
  }
  /* @media screen and (max-width: 768px){
    .disclaimerContainer{
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    .disclaimer{
      width: 400px;
      height: 450px;
    }
    .disclaimerText{
      font-size: 10px;
    }
    
  } */
  .accdec{
    position: relative;
    display: flex;
    flex-direction: row;
   margin-left: 68% ;
   margin-right: 0% !important;
  }
  .acceptButton {
    margin: 3% !important;
    width: 150px !important;
    padding: 15px 25px !important;
    background: #001f3f;
    color: #fff;
    
    /* margin-left: 160% !important; */
    border: none;
  }
  
  .declineButton {
    margin: 3% !important;
    width: 150px !important;
    height: 60px !important;
    padding: 15px 25px !important;
    background: #d9d9d9;
    border: none;
  }
  
  /* @media (max-width: 768px) {
    .acceptButton {
      padding: 8px 16px;
      font-size: 16px;
    }
    
    .declineButton {
      padding: 12px 20px;
      font-size: 16px;
    }
  } */