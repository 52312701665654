.ni1{
    padding: 10px;
    display: flex;
    flex-direction: row;
}
.ni1 h1{
    color: #16214f;
    margin-left: 50px;
    margin-top: 90px;
    font-weight: bold;
    font-size: 50px;
}
/* @media screen and (max-width: 896px){
    .ni1 h1{
        font-size: 22px;
        margin-top: 20px;
    }
    .blogs{
        flex-direction: column !important;
        
    }
    .blogsection2{
        top: -6.5vh;
        position: relative;
    }
    .blogcard{
        position: relative;
        height: 45vh;
        width: 38vh;
    }
    .blogcard4{
        height: 48vh;
    }
    .cardimage{
        width: 50vh !important;
        height: 20vh !important;
        left: 0% !important;
    }
    .bloghead{
        position: relative;
        width: 38vh;
        left: 11vh;
        font-size: 18px !important;
    }
    .blogdetail{
        position: relative;
        font-size: 12px !important;
        width: 38vh;
        left: 11vh;
    }
    .datelink{
        top: 0vh;
        left: 11vh;
        width: 40vh;
        position: relative;
    }
  
    .readButton{
        width: 100vh !important;
    }
    .dateblog{
        margin-left: -72vh !important;
    }
    .newsfooter{
        position: relative;
        top: -133vh;
    }
    .link{
        position: relative;
        width: 800vh !important;
        display: block;
    }

} */
/* @media screen and (max-width: 375px){
    .blogcard{
        width: 40vh;
        height: 60vh;
    }
    .cardimage{
        width: 52vh !important;
    }
    .blogcard4{
        height: 65vh;
        width: 40vh;
    }
    .newsfooter{
        top: 0vh;
        height: 0vh !important;
    }
} */
/* @media screen and (max-width: 390px){
    .bloghead{
        font-size: 14.5px !important;
    }
    #bloghead4{
        font-size: 15.5px !important;
    }
    .blogdetail{
        width: 290px ;
    }
    .blogcard4{
        height: 50vh;
    }
} */
/* @media screen and (max-width: 430px){
    .bloghead{
        font-size: 15.5px !important;
    }
    #bloghead4{
        font-size: 14.5px !important;
    }
    .blogdetail{
        width: 320px ;
    }
    .newsfooter{
        height: 0vh !important;
        top: 0vh;
    }

} */
/* @media screen and (max-width: 412px)
{
    #bloghead4{
        width: 310px;
    }
    #blogdetail4{
        width: 310px;
    }
}
@media screen and (max-width: 360px){
    .blogcard{
        height: 52vh;
    }
    .blogdetail{
        width: 290px;
    }
    #blogdetail4{
        width: 270px;
    }
} */
.ni1 img{
    width: 50%;
}
.blogs , .blogsection2{
    padding: 40px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* margin: 20px; */
}
.readButton{
    padding: 10px;
    font-size: 15px;
    text-decoration: none;
    color: black;
    transition: transform 1s ease;
}
/* .readButton:hover{
    transition: transform 1s ease;
    font-size: 18px;
} */
.link{
    text-decoration: none;
    color: black;
}